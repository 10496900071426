import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KioskSettingsService {
  api =  environment.baseUrl + "configuration";

  constructor(private httpClient: HttpClient) { }

  getSetting(param: string):Observable<any>{
    return this.httpClient.get(this.api+"/getByParameter/" + param);
  }

}
