<div class="visualCue bg-accent" [class.hide]="!open">
    <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="content">
        <p>
            {{data}}
        </p>
        <!-- <button mat-stroked-button class="next" 
        (click)="onNext()"
        color="primary">Next</button> -->
    </div>
</div>