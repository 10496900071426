import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  api =  this.apiConfig.getBaseUrl  + 'payment-gateway';

  constructor(private apiConfig: HttpConfigService) { }

  getAllPaymentGateways(storeId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}/client/${storeId}/qrapp`);
  }

  getStripeProcessingFee(): Observable<any> {
    return this.apiConfig.get(this.api + '/client/stripe/fee')
  }
}
