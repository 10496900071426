import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DesignUtilityServices } from "src/app/appServices/design-utility.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HttpConfigService {
    public get getBaseUrl() {
        return environment.baseUrl;
    }
    public get envProd() {
        return environment.production;
    }
    constructor(
        protected httpClient: HttpClient,
        private _du: DesignUtilityServices,
        private cookieService: CookieService
    ) { }

    public get(url, options?: any | {}): Observable<any> {
        return this.httpClient.get(url, options).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    public post(url, data: any, options?: any | {}): Observable<any> {
        return this.httpClient.post(url, data, options).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    public delete(url, options?: any | {}): Observable<any> {
        return this.httpClient.delete(url, options).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    public put(url, data?: any | {}, options?: any | {}): Observable<any> {
        return this.httpClient.put(url, data, options).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    public patch(url, data?: any | {}, options?: any | {}): Observable<any> {
        return this.httpClient.patch(url, data, options).pipe(
            catchError(this.handleError.bind(this))
        );
    }


    private handleError(error: HttpErrorResponse) {
        if (error?.error?.error === 'Unauthorized') {
            this.cookieService.delete('token');
            this.cookieService.delete('username');   
        }
        if (error?.status === 0 || error?.status === 500) {
            console.error('An error occurred:', error.error);
            this._du.openCustomSnackBar(error?.error?.details[0], 'Close', 'error');
        } else {
            console.error(
                `Backend returned code ${error?.status}, ` +
                `Message was: ${error?.error?.message}`);
            this._du.openCustomSnackBar('Server Error', 'Close', 'error');
        }
        return throwError(
            'Something bad happened; please try again later.');
    }
}