import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TenantResolver } from 'src/app/appStorage/tenant.resolver';

@Injectable({
  providedIn: 'root'
})
export class TenentIdentifierInterceptor implements HttpInterceptor {
  tenant:any;
  constructor(
    private _tenantResolver: TenantResolver) { 
      try {
        this.tenant = this._tenantResolver.getTenantName();
      } catch(err) {
        throw 'No Tenant/Table Number found';
      }}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.addTenantToHeaders(request.headers);

    request = request.clone({
      headers: headers
    });

    return next.handle(request)
      .pipe(
        filter(event => event instanceof HttpResponse),
        tap((event: HttpResponse<any>) => {
        }));
  }


  /**
  * create new header with tenant name
  * @param headers 
  * @returns 
  */
  private addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    const tenantName = this._tenantResolver.getTenantName();
    if (tenantName) {
      return headers.append('tenant-name', tenantName);
    }
  }

  
}