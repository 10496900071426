<mat-selection-list 
    (selectionChange)="onSelectionChange($event.option.value)" 
    [multiple]="false">
    <mat-list-option 
        *ngFor="let item of inputList" 
        [value]="item" 
        [selected]="selectedInput?.id==item.id"
        >
        {{item[viewKey]}}
    </mat-list-option>
</mat-selection-list>