import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BnNgIdleService } from 'bn-ng-idle';
import { CookieService } from 'ngx-cookie-service';
import { NgxStripeModule } from 'ngx-stripe';
import { ErrorMessageService } from 'src/app/core/errorHandler/error-message.service';
import { GlobalErrorHandler } from 'src/app/core/errorHandler/global-error-handler';
import { TenentIdentifierInterceptor } from 'src/app/core/tenantIdentifier/tenent-identifier.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './appModules/core.module';
import { DesignUtilitiesModule } from './appModules/design-utilities.module';
import { WindowService } from 'src/app/core/utilities/window.service';
import { APP_BASE_HREF } from '@angular/common';

export function getBaseUrl(windowService: WindowService) {
  if (windowService.getDocumentRefrence().location.pathname !== "") {
    const path = windowService.getDocumentRefrence().location.pathname?.split('/');
    if (path.length < 3) {
      alert('No domain name / Store found');
      return;
    }
    const domain = path[1];
    // const tenantName = sessionStorage.getItem('tenant');
    const store = path[2];
    let tableNumber = null;
    if (path.length > 3) {
      tableNumber = path[3];
    }

    if (tableNumber) {
      return "/".concat(domain).concat("/").concat(store).concat("/").concat(tableNumber);
    } else {
      return "/".concat(domain).concat("/").concat(store);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    DesignUtilitiesModule,
    NgxStripeModule.forRoot(),
  ],
  providers: [
    BnNgIdleService,
    { provide: APP_BASE_HREF, useFactory: getBaseUrl, deps: [WindowService] },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenentIdentifierInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ErrorMessageService
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
