import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionListComponent{

  @Input() inputList:any[];
  @Input() viewKey:string;
  @Input() selectedInput:any;
  @Output() onModelChanged = new EventEmitter<any>();
  
  onSelectionChange(event) {
    this.onModelChanged.emit(event);
  }
}
