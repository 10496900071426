import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { AppInitializerService } from 'src/app/core/appInit/app-initializer.service';
import { AppInitApiService } from 'src/app/core/appInit/app-init-api.service';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @HostBinding('class') componentCssClass;

  constructor(
    private _appInit:AppInitializerService,
    private _appInitApi: AppInitApiService,
    private _du: DesignUtilityServices,
    ) {
    this.getThemeClass();
  }


  ngOnInit(): void {
    this._appInit.appInitializer();
    this._appInitApi.initApis();
  }


  /**
   * Get Theme Class
  */
  getThemeClass(){
    this._du.theme.subscribe(res => {
      this.componentCssClass = res
    });
  }

}
