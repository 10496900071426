<div class="user-info-container" [ngClass]="{'portrait':portraitView}">
    <div class="row">
        <div class="col-left">
            <h1 mat-dialog-title class="flex-middle">
                <ng-container *ngIf="data==='back'">Do you want to go back to home?</ng-container>
                <ng-container *ngIf="data==='clearCart'">Do you want to clear cart?</ng-container>
            </h1>
            <ng-container *ngIf="portraitView">
                <div mat-dialog-content>
                    <ng-container *ngTemplateOutlet="proList"></ng-container>
                    
                    <ng-container *ngTemplateOutlet="summary"></ng-container>
                </div>
            </ng-container>
            <ng-container *ngTemplateOutlet="btnRow"></ng-container>
        </div>
    </div>
    
</div>


<ng-template #btnRow>
    <div class="options">
        <a class="bg-app foreground" 
        (click)="onNoClick()">
            <figure>
                <img src="/assets/images/mark.svg" alt="">
            </figure>
            Discard
        </a>
        <a class="bg-app foreground" 
        [mat-dialog-close]="'confirm'">
        <figure>
            <img src="/assets/images/tick.svg" alt="">
        </figure>
        Confirm
        </a>
    </div>
</ng-template>
 