import { BehaviorSubject } from 'rxjs'; import { Injectable } from '@angular/core';
import { IStore } from '../appModels/IStore';
import { StoresService } from '../appServices/stores.service';

@Injectable({
    providedIn: 'root'
})
export class StoreResolver {
    store = new BehaviorSubject<IStore>(null);

    constructor(private storeService: StoresService) { }
    /**
     * set store
     * @param store 
     */
    setStore(store: IStore) {
        this.store.next(store);
    }

    /**
     * get store by table number
     * @param tableNumber 
     */
    getStoreByTableNumber(tableNumber: string) {
        this.storeService.getStoreByTableNumber(tableNumber).subscribe(res => {
           res && this.setStore(res);
        });
    }

    /**
     * get store by store id
     * @param storeId 
     */
    getStoreByStoreId(storeId: number){
        this.storeService.getStoreByStoreId(storeId).subscribe(res => {
            res && this.setStore(res);
         });
    }

    /**
     * Get Tenant By Domain 
     * @param domain 
     * @returns 
     */
    getTenantByDomain(domain: string) {
      return this.storeService.getTenantByDomain(domain);
    }




    /**
     * clear store
     */
    clearStore() {
        this.store.next(null);
    }
}