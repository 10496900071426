import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { ConnectionService } from 'ng-connection-service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { UserInactivityModelComponent } from 'src/app/includes/user-inactivity-model/user-inactivity-model.component';
import { skipInactivityUrls, skipOrderTypeModelUrls } from 'src/app/appConstants/skipInactivityUrls';
import { BreakpointObserver } from '@angular/cdk/layout';
import { HelperService } from 'src/app/appServices/helper.service';
import { OrderTypeResolver } from 'src/app/appStorage/order-type.resolver';
import { OrderTypeDialogComponent } from 'src/app/includes/popups/order-type-dialog/order-type-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

  isConnected = true;
  inactivityModelOpened = false;
  skipInactivityModel: boolean = false;
  inactivityTime:number = 30;
  // inactivityTime: number = 3000;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,

    private connectionService: ConnectionService,
    private _du: DesignUtilityServices,
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private helperService: HelperService,
    private _orderTypeResolver: OrderTypeResolver

  ) { }

  /**
   * App Initializer
  */
  appInitializer() {
    this.clearApp();
    this.checkUrlToSkipInactivityModel();
    this.checkUrlToSkipOrderTypeModel();
    this.getViewMode();
    this.getConnectionStatus();

    this.routerEventInit();
    this.checkUserInactivity();
  }



  clearApp(){
    sessionStorage.clear();
    localStorage.clear();
  }





  /**
   * View Mode of Screen
  */
  getViewMode() {
    this.breakpointObserver.observe([
      '(orientation: portrait)'
    ]).subscribe(result => {
      this.helperService.portraitView.next(result.matches);
    });
  }



  /**
   * Router Events
  */
  routerEventInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        setTimeout(() => {
          // document.getElementById('wrapper').style.top = null;
          document.getElementsByTagName('html')[0].classList.remove('hideScroll');
          window.scrollTo(0, 0);
          // this._du.menuToggler.next(false);
        }, 200);
      });
  }


  /**
   * Get Internet 
   * Connection Status
  */
  getConnectionStatus() {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this._du.internetStatus.next('ONLINE');
      }
      else {
        this._du.internetStatus.next('OFFLINE');
      }
    })
  }



  /**
   * Check Url to skip 
   * Inactivity Model 
  */
  checkUrlToSkipInactivityModel() {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (skipInactivityUrls.includes(this.router.url)) {
            this.skipInactivityModel = true;
          } else {
            this.skipInactivityModel = false;
          }
        }
      }
    );
  }






  /**
   * Check User 
   * Inactivity Status
  */
  checkUserInactivity() {
    this.bnIdle.startWatching(this.inactivityTime).subscribe((isTimedOut: boolean) => {
      if (isTimedOut && !this.inactivityModelOpened && !this.skipInactivityModel && this.isConnected) {
        this.inactivityModelOpened = true;
        this.openInactivityModel();
      }
    });
  }

  openInactivityModel(): void {
    const dialogRef = this.dialog.open(UserInactivityModelComponent, {
      maxWidth: '90%',
      minWidth: '90%',
      // height: '90%',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.inactivityModelOpened = false;
    });
  }



  /**
   * Clear Application (Unused yet)
   */
  // clearApp() {
  //   this.cartStorage.clearCartWithOutDialog();
  //   this.orderService.activeOrder.next(null);
  // }

  /**
   * Subscribe Selected Order Type
  */
  subscribeSelectedOrderType() {
    this._orderTypeResolver.selectedOrderType.subscribe(res => {
      if (!res) {
        this.openOrderTypeDialog();
      }
    })
  }


  /**
   * check url to skip order type model
   */
  checkUrlToSkipOrderTypeModel() {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (!skipOrderTypeModelUrls.includes(this.router.url)) {
            this.subscribeSelectedOrderType();
          }
        }
      }
    );
  }

  /**
   * Order Type Dialog
  */
  openOrderTypeDialog() {
    this.dialog.open(OrderTypeDialogComponent, {
      minWidth: '80%',
      maxWidth: '300px',
      panelClass: ['bg-app'],
      disableClose: true
    });
  }

}
