import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { KioskSettingsService } from 'src/app/appServices/kiosk-settings.service';
import { PaymentGatewayService } from 'src/app/appServices/payment-gateway.service';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { NumberUtility } from 'src/app/appUtilities/number.utility';

@Injectable({
  providedIn: 'root'
})
export class CheckoutResolver {
  subTotalAmount = new BehaviorSubject(0);
  tipAmount = new BehaviorSubject(0);
  tipToggleVal = new BehaviorSubject<any>(null);
  tax = new BehaviorSubject<any>(null);
  stripeProcessingFee = new BehaviorSubject<any>(null);
  paymentMethods = new BehaviorSubject<any[]>(null);
  selectedStore: any;
  public platformFee = 0;
  constructor(
    private kioskSettingService: KioskSettingsService,
    private paymentGatewayService: PaymentGatewayService,
    private storeResolver: StoreResolver,
    private numberUtility: NumberUtility
  ) {
  }

  initChekoutApis() {
    this.subscribeStore();
    this.getTax();
    this.getProcessingFee();
  }

  /**
   * subscribe store
   */
  subscribeStore() {
    this.storeResolver.store.subscribe(res => {
      this.selectedStore = res;
      this.getPaymentMethods();
    });
  }

  /**
   * get Tax
   */
  getTax() {
    this.kioskSettingService.getSetting('Tax').subscribe(res => {
      this.tax.next(res);
    });
  }

  getProcessingFee() {
    this.paymentGatewayService.getStripeProcessingFee().subscribe(res => {
      this.stripeProcessingFee.next(res);
    });
  }

  /**
   * get Payment Methods
   */
  getPaymentMethods() {
    this.paymentGatewayService.getAllPaymentGateways(this.selectedStore.id).subscribe(res => {
      res && this.paymentMethods.next(res);
    }); 
  }


  /**
   * get platformfee from rules
   * @param totalAmount 
   * @param platformFeeRules 
   * @returns 
   */ 
   getPlatformFeeFromRules(totalAmount: number, platformFeeRules: any[],  defaultPlatformFee: number) {
    let result = 0;
    for (let i = 0; i < platformFeeRules.length; i++) {
      const min = platformFeeRules[i].minOrderAmount;
      const max = platformFeeRules[i].maxOrderAmount;
      if (totalAmount > min && totalAmount <= max) {
        result = platformFeeRules[i].fee;
        break;
      }
    }
    return result > 0 ? result : defaultPlatformFee;
  }


  /**
   * calculateProcessing Fee
   */
  calculateProcessingFee(totalAmount: number, paymentMode: string, serviceFeeModel:any): number {
    if (paymentMode.toUpperCase() !== 'Cash On Delivery'.toUpperCase() && serviceFeeModel) {
      const fixed_fee = serviceFeeModel.fixedFee;
      const percent_fee = serviceFeeModel.percentageFee * 0.01;
      this.platformFee =this.getPlatformFeeFromRules(totalAmount, serviceFeeModel?.platformFeeRules, serviceFeeModel?.platformFee);
      const amountToPay = ((totalAmount + this.platformFee) + fixed_fee) / (1 - percent_fee);
      return this.numberUtility.roundToTwo(amountToPay - totalAmount); 
    } else {
      return 0;
    }
  }

}
