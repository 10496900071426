import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ITable } from '../appModels/ITable';
import { TableService } from 'src/app/appServices/table.service';

@Injectable({
    providedIn: 'root'
})
export class TableResolver {
    table = new BehaviorSubject<ITable>(null);

    constructor(private tableService: TableService) {

    }
    /**
     * Initialize Table
     * @param orderTypes 
     */
    setTable(table: ITable) {
        this.table.next(table);
    }

    /**
     * get table from API
     * @param tableNumber 
     */
    getTable(tableNumber: string) {
        this.tableService.getTable(tableNumber).subscribe(res => {
            res && this.setTable(res);
        });
    }


    /**
     * clear table
     */
    clearTable() {
        this.table.next(null);
    }
}