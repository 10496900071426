// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   baseUrl: 'https://main-api.preprod-tastio.com/app/v1/',
  S3_URL : 'https://tastio-testing.ams3.digitaloceanspaces.com/',
  SPACE_NAME: 'tastio-testing',
  STRIPE_PUBLIC_KEY: 'pk_test_51IfgJAKBSFkAswCEyeeJrPs3IrVUXwtI5JEIooa46y2egewCc7rZAjaz6ogCZQJPcbujGax3UNmtWWKh3yaU8k6D00rUBLne4r'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
