import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HelperService } from 'src/app/appServices/helper.service';
import { CartStorage } from 'src/app/appStorage/cart.storage';

@Component({
  selector: 'app-user-inactivity-model',
  templateUrl: './user-inactivity-model.component.html',
  styleUrls: ['./user-inactivity-model.component.scss']
})
export class UserInactivityModelComponent implements OnInit {
  portraitView = false;

  constructor(
    public dialogRef: MatDialogRef<UserInactivityModelComponent>,
    private cartStorage: CartStorage,
    private _helperService: HelperService,
  ) {
    this._helperService.portraitView.subscribe(res =>{
      this.portraitView = res;
    });
   }


  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goToHome() {
    this.onNoClick();
    this.cartStorage.clearCart();
  }
}
