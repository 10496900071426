import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  api = this.apiConfig.getBaseUrl + 'table';

  constructor(private apiConfig: HttpConfigService) { }

  /**
   * get table
   * @param tableNumber 
   */
  getTable(tableNumber: string): Observable<any> {
    return this.apiConfig.get(this.api + "/" + tableNumber);
  }
}
