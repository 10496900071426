import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class StoresService {


  api = this.apiConfig.getBaseUrl;

  constructor(private apiConfig: HttpConfigService) {}

  /**
   * get store by table number
   * @param tableNumber 
   * @returns 
   */
  getStoreByTableNumber(tableNumber: string) : Observable<any>  {
    return this.apiConfig.get(this.api + "store/table/" + tableNumber);
  }


  /**
   * get store by store id
   * @param storeId 
   * @returns 
   */
  getStoreByStoreId(storeId: number): Observable<any> {
    return this.apiConfig.get(this.api + "store/" + storeId);
  }


  /**
   * Get Tenant By Domain 
   * @param domain 
   * @returns 
   */
  getTenantByDomain(domain: string): Observable<any> {
    return this.apiConfig.get(`${this.api}tenant/domain/${domain}`);
  }

}
