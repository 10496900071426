import { Inject, Injectable } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { StoreResolver } from 'src/app/appStorage/store.resolver';
import { TableResolver } from 'src/app/appStorage/table.resolver';
import { TenantResolver } from 'src/app/appStorage/tenant.resolver';
import { OrderTypeResolver } from 'src/app/appStorage/order-type.resolver';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';


@Injectable({
  providedIn: 'root'
})
export class AppInitApiService {

  constructor(
    private storeResolver: StoreResolver,
    private _tenantResolver: TenantResolver,
    private tableResolver: TableResolver,
    private restaurantBasicInfo: RestaurantBasicInfoStorage,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private orderTypeResolver: OrderTypeResolver
  ) { }

  initApis() {
    this.getTenantByDomain();
  }

  /**
  * Get Basic Info
 */
  initBasicInfo() {
    this.restaurantBasicInfo.getBasicInfo();
  }

  /**
   * Get Tenant By Domain 
   * @param domain 
   */
  getTenantByDomain() {
    const domain = this.baseHref.split('/')[1];
    this.storeResolver.getTenantByDomain(domain).subscribe(
      (res)=>{
        this._tenantResolver.setTenantName(res);
        this.initBasicInfo();
        this.setStoreAndTable();
        this.subscirbeStore();
      },
      (err)=>{
        console.warn('error => ',err)
      },
    );
  }


  /**
   * set table number
   */
  setStoreAndTable() {
    const baseUrl = this.baseHref.split('/');
    const storeId = Number.parseInt(baseUrl[2]);
    this.storeResolver.getStoreByStoreId(storeId);
    const tableNumber = baseUrl[3];
    if (tableNumber) {
      try {
        this.tableResolver.getTable(tableNumber);
      } catch (err) {
        throw 'No Table Number found';
      }
    }

  }

  /**
   * subscribe store
   */
  subscirbeStore() {
    this.storeResolver.store.subscribe(res => {
      res && this.orderTypeResolver.getOrderTypes(res.id);
    });
  }


}
