import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  api = this.apiConfig.getBaseUrl;

  activeOrder = new BehaviorSubject(null);

  constructor(private apiConfig: HttpConfigService) {

  }

  createOrder(order): Observable<any> {
    return this.apiConfig.post(this.api + "order/guest/checkout", order);
  }

  getOrderTypes(storeId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}store/${storeId}/orderTypes/qrapp`);
  }

  makeOnlinePayment(onliePaymentModel): Observable<any> {
    return this.apiConfig.post(this.api + 'order/online-payment', onliePaymentModel);
  }

}
