<div class="user-info-container" [ngClass]="{'portrait':portraitView}">
    <h1 mat-dialog-title class="flex-middle">
        Are you still looking?
    </h1>
    <div mat-dialog-content>
        <div class="flex-middle">
            <app-countdown [running]="true"></app-countdown>
            <!-- <img src="assets/images/alarm.svg" /> -->
        </div>
    </div>
    <ng-container *ngTemplateOutlet="btnRow"></ng-container>
</div>


<ng-template #btnRow>
    <div class="options">
        <a class="bg-app foreground" 
        (click)="onNoClick()">
            <figure>
                <img src="/assets/images/return.svg" alt="">
            </figure>
            Return
        </a>
        <a class="bg-app foreground" 
        (click)="goToHome()">
        <figure>
            <img src="/assets/images/new-order.svg" alt="">
        </figure>
        New Order
        </a>
    </div>
</ng-template>