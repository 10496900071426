import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from 'src/app/appServices/helper.service';

@Component({
  selector: 'app-back-prompt',
  templateUrl: './back-prompt.component.html',
  styleUrls: ['./back-prompt.component.scss']
})
export class BackPromptComponent implements OnInit {
  portraitView = false;
  basicInfo: any;

  constructor(
    public dialogRef: MatDialogRef<BackPromptComponent>,
    private _helperService: HelperService,
    @Inject(MAT_DIALOG_DATA) public data) {
    this._helperService.portraitView.subscribe(res =>{
      this.portraitView = res;
    });
   }


  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goToHome() {
    this.onNoClick();
  }
  
}
