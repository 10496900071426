  <div class="board background-50">
    <div class="switch">
        <div class="button" [class.on]="theme=='light-theme'" (click)="darkThemeEnable = !darkThemeEnable; setTheme()">
        </div>
    </div>
    <span class="text">
        <small>
            Switch {{theme=='light-theme'?'Off': 'On'}} to <span [ngClass]="{
                'primary': theme=='light-theme',
                'accent': theme=='dark-theme'
            }"
            
            >turn {{theme=='light-theme'?'Off': 'On'}}</span> 
        </small>
        <span [ngClass]="{
            'accent': theme=='light-theme',
            'primary': theme=='dark-theme'
        }"
        >Light Mode</span>
    </span>
</div>