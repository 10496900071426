import { Directive, Input, HostListener, ElementRef, HostBinding } from '@angular/core';

declare var $: any;
@Directive({
  selector: '[appGoNext]'
})
export class GoNextDirective {
  @Input('appGoNext') goNextId: string;
  @HostListener('click')
  click() {
    setTimeout(() => {
      if (this.goNextId !== null) {
        document
          .getElementById(this.goNextId)
          .scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
    return;
  }
}
