<div class="countdown">
    <h2>You will be redirecting to the home in</h2>
    <div class="spinner">
        <mat-progress-spinner
            color="primary"
            mode="determinate"
            [value]="percentage"
            diameter = "150">
        </mat-progress-spinner>
        <span class="count">{{count}}</span>
        <span class="seconds">Seconds</span>
    </div>
</div>
 