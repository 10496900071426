import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestaurantInfoService } from '../appServices/restaurant-info.service';

@Injectable({
    providedIn: 'root'
})
export class RestaurantBasicInfoStorage {
    restaurantBasicInfo = new BehaviorSubject<any>(null);
    constructor(private restaurantInfoService: RestaurantInfoService) {
        this.getBasicInfo();
     }

    getBasicInfo() {
        if (!sessionStorage.getItem("basic-info")) {
            this.setRestaurantBasicInfoFromApi();
        } else {
            this.restaurantBasicInfo.next(JSON.parse(sessionStorage.getItem("basic-info")));
        }
    }

    setRestaurantBasicInfoFromApi() {
        this.restaurantInfoService.getBasicInfo().subscribe(res => {
            sessionStorage.setItem("basic-info", JSON.stringify(res));
            this.restaurantBasicInfo.next(res);
        })
    }

}